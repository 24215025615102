// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-audit-tsx": () => import("./../src/pages/audit.tsx" /* webpackChunkName: "component---src-pages-audit-tsx" */),
  "component---src-pages-cookies-tsx": () => import("./../src/pages/cookies.tsx" /* webpackChunkName: "component---src-pages-cookies-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-r-tsx": () => import("./../src/pages/r.tsx" /* webpackChunkName: "component---src-pages-r-tsx" */),
  "component---src-pages-unsubscribe-tsx": () => import("./../src/pages/unsubscribe.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-tsx" */)
}

